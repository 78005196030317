import React, { useMemo } from 'react';
import { View, Image, Text, StyleSheet, Pressable } from 'react-native';
import { useSelector } from 'react-redux';
import { vwToPx } from '../../modules/ConvertPXToVw';

const PlayerImages = ({ players, e, index, popupToList, containerRef, hover, setHover, onPress }) => {
  const displayedPlayers = players.filter((plr) => e.to_list?.indexOf(plr?.id) !== -1).slice(0, 4);
  const extraPlayers = players.filter((plr) => e.to_list?.indexOf(plr?.id) !== -1).length - 4;

  const color = useSelector((state) => state.style.color);
  const background = useSelector((state) => state.style.background);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(background, color);

  const getInitials = (name) => {
    if (!name) return '';
    const nameParts = name.split(' ');
    if (nameParts.length === 1) {
      return nameParts[0][0].toUpperCase() + nameParts[0][1]?.toUpperCase();
    } else if (nameParts.length > 1) {
      return nameParts[0][0].toUpperCase() + nameParts[1][0]?.toUpperCase();
    }
    return '';
  };

  return (
    <Pressable
      ref={(ref) => ref && !popupToList.current[index] && popupToList.current.push(ref)}
      onPress={onPress}
      onHoverIn={() => {
        if (!hover) {
          setHover({
            x: popupToList.current[index].getBoundingClientRect().x -
              (containerRef.current?.getBoundingClientRect().x + vwToPx(5.5)) + (popupToList.current[index].getBoundingClientRect().width / 3),
            y: popupToList.current[index].getBoundingClientRect().y -
              (containerRef.current?.getBoundingClientRect().y - vwToPx(2)),
            to_list: e.to_list,
            ref: popupToList.current[index]
          });
        }
      }}
      style={styles.container}
    >
      {({ hovered }) => (
        <>
          {displayedPlayers.map((player, index) => (
            <View
              style={[
                styles.imgView,
                {
                  ...(direction === "ltr"
                    ? { marginLeft: index > 0 ? '-0.2vw' : 0 }
                    : { marginRight: index > 0 ? '-0.2vw' : 0 }),
                  zIndex: displayedPlayers.length - index
                }
              ]}
              key={index}
            >
              {player?.profile_image ? (
                <Image
                  source={player.profile_image}
                  style={styles.logo}
                />
              ) : (
                <View style={styles.initialsView}>
                  <Text style={styles.initialsText}>{getInitials(player.name_for_game)}</Text>
                </View>
              )}
            </View>
          ))}
          {extraPlayers > 0 && (
            <View style={direction === "ltr" ? { marginLeft: '0.3vw' } : { marginRight: "0.3vw" }}>
              <Text style={styles.extraText}>+{extraPlayers}</Text>
            </View>
          )}
        </>
      )}
    </Pressable>
  );
};

const stylesR = (background, color) => StyleSheet.create({
  container: {
    width: '12vw',
    maxWidth: '12vw',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  imgView: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: '0.1vw',
    backgroundColor: background.chatCreatorBorder,
    overflow: 'hidden',
    width: '2vw', // Adjust as needed
    height: '2vw', // Adjust as needed
  },
  logo: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: '50%',
  },
  initialsView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: background.infoBody,
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  initialsText: {
    color: color.text2,
    fontWeight: "500",
    fontSize: '0.9vw', // Adjust as needed
  },
  extraView: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: background.chatCreatorBorder,
  },
  extraText: {
    color: color.text2,
    fontSize: '0.9vw', // Adjust as needed
  },
});

export default PlayerImages;
