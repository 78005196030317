import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EDIT_GAME_PATH,
  ADD_NEW_GAME,
  GET_GAME_PATH,
  URL_PATH,
  COUNT_DOWN_VIDEO,
  SAVE_PLAYER_FOR_GAME,
  REMOVE_PLAYER,
  RESEND_INVITE,
  DELETE_DOC_PATH,
  REMOVE_COUNTDOWN_VIDEO,
  GET_CLIENT_USER
} from "@env";
import {
  CREATE_GAME_PERMISSION,
  F_PHPSESSID,
  GAME_ID,
  USER_ID_IN_GAME,
  _FILE0_,
  _VIDEO_,
  _F_PHPSESSID_,
  _ID_,
  _TEXT_NEW_GOALS,
  _PERMISSION_FOR_GAME_,
  _EMAIL_,
  _REPRESENTED_,
  _SHOW_IN_CHAT_,
  _USER_ID_,
  _NAME_FOR_GAME_,
  _PLAYER_DESC_,
  _OBSERVER_NAME_,
  USER,
  USER_ID_IN_GAME_OBSERVER,
} from "../config.inc";
import {
  changeLangType,
  setGameInfo,
  setLogin,
  setToken,
  setStartGame,
  setInGame,
  setGamePermission,
  setUser,
  removeBackgroundFile,
  removeCountdownVideo,
  end,
  setCostumColors,
  addLogo,
  setClientsForUser,
  setErrorMessage,
} from "../redux/actions";
import { getLang } from "../getLang";
import {
  openRealPlayer,
  setRealPlayers,
  setReplPlayers,
} from "../redux/actions/creatorActions";
import { LightenDarkenColor } from "../componnets/modules/LightenDarkenColor";
import { hexToCSSFilter } from 'hex-to-css-filter';
import moment from 'moment-timezone';
import { UpdateContext } from "./Update";
import { t } from "i18n-js";
export const GameInfoContext = createContext();

export default function GameInfoProvider(props) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginType.token);
  const gameInfo = useSelector((state) => state.gameInfoArr.gameInfoArr);

  const { getUpdateData } = useContext(UpdateContext);

  const mapPlayers = (playersArr) => {
    let realP = [];
    let repP = [];
    if (
      playersArr &&
      Array.isArray(playersArr.players) &&
      playersArr.players.length > 0
    ) {
      playersArr.players.forEach((player, i) => {
        if (
          player.is_represented === "1"
        ) {
          repP.push(player);
        } else if (player.permission_for_game === "15" && player.id !== sessionStorage.getItem(USER_ID_IN_GAME)) {
          realP.push(player);
        }
      });
    }
    return {
      real: realP,
      rep: repP,
    };
  };

  const setColors = (colorsString) => {
    try {
      let colors = colorsString?.game_colors.split(",");
      dispatch(setCostumColors({
        color1: `#${colors[0]}`,
        colorShade1: LightenDarkenColor(`#${colors[0]}`, 0),
        colorCurrent1: `#${colors[0]}`,
        color2: `#${colors[1]}`,
        colorShade2: LightenDarkenColor(`#${colors[1]}`, 0),
        colorCurrent2: `#${colors[1]}`,
        color3: `#${colors[2]}`,
        colorShade3: LightenDarkenColor(`#${colors[2]}`, 0),
        colorCurrent3: `#${colors[2]}`,
      }, true));
    } catch (e) {
      console.log(e);
    }
  }

  function convertToUTC(timeString, dateString, timeZone) {
    // המרת התאריך והשעה ל־UTC
    const dateTime = moment.tz(`${dateString} ${timeString}`, "ddd MMM DD YYYY hh:mm A", timeZone);
    const utcDate = dateTime.utc().format('YYYY-MM-DD');
    const utcTime = dateTime.utc().format('HH:mm');

    return { date: utcDate, time: utcTime };
  }

  const checkIfIsObserver = (game_info) => {
    if (sessionStorage.getItem(CREATE_GAME_PERMISSION) !== "1") {
      let observer = game_info?.players?.find(
        (player) =>
          player.id === sessionStorage.getItem(USER_ID_IN_GAME)
      )?.observer_id;
      if (observer) {
        sessionStorage.setItem(USER_ID_IN_GAME_OBSERVER, observer);
      }
    }
  }

  const getGameInfoData = () => {
    const url = `${URL_PATH}${GET_GAME_PATH}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    body.append(_ID_, sessionStorage.getItem(GAME_ID));
    const requedtOptions = { method: "POST", body: body };
    try {
      fetch(url, requedtOptions)
        .then((res) => res?.text())
        .then((res) => {
          res = JSON.parse(res);
          // Reverse this line to comment because it canceled the endGame page for players
          if (res.status === "true"
            // && window.sessionStorage.getItem(GAME_ID)
          ) {
            let inGame = checkStartGame(res);
            if (inGame) {
              if (res.game_info.game_colors) {
                setColors(res.game_info);
              } else {
                dispatch(setCostumColors({}, false));
              }
              if (res.game_info?.game_logo) {
                dispatch(addLogo(res.game_info.game_logo + '?timestamp=' + new Date().getTime()));
              }
              let player = res.game_info?.players?.find(
                (player) =>
                  player.id === sessionStorage.getItem(USER_ID_IN_GAME)
              );
              dispatch(
                setGamePermission(
                  player?.permission_for_game === "10" ? "10" : sessionStorage.getItem(CREATE_GAME_PERMISSION) === "1" ? "1" :
                    player?.permission_for_game
                )
              );
              let current = res.game_info?.players?.find((e) => e.id === sessionStorage.getItem(USER_ID_IN_GAME));
              dispatch(setUser({ ...JSON.parse(sessionStorage.getItem(USER))?.user, profile_image: current?.profile_image }));
              dispatch(setGameInfo(res.game_info));
              dispatch(changeLangType(getLang(res.game_info.game_lang)));
              let { real, rep } = mapPlayers(res.game_info);
              checkIfIsObserver(res?.game_info);
              dispatch(setRealPlayers(real?.filter((e) => !e.observer_id)));
              dispatch(setReplPlayers(rep));
              props.sentMessageWebSocket("get_active_players", "all");
              getClientsUser();
            }
          } else {
            dispatch(setGameInfo({}));
            dispatch(changeLangType(getLang("English")));
            sessionStorage.removeItem(GAME_ID);
            dispatch(setToken(false));
            dispatch(setLogin());
          }
        })
        .catch((err) => { });
    } catch (e) { }
  };

  const addBodyToEdit = (body, obj, type) => {
    return new Promise((reslove, reject) => {
      let { date, time } = convertToUTC(obj.time, obj.date, obj.game_time_zone);
      type.forEach((e) => {
        switch (e) {
          case "is_demo":
            body.append("is_demo", obj.is_demo ? "1" : "0");
            return reslove(true);
          case "story":
            body.append("background_story", obj.story?.length ? encodeURI(obj.story) : " ");
            return reslove(true);
          case "game_goals":
            body.append("game_goal", obj.game_goals?.length ? encodeURI(obj.game_goals) : " ");
            return reslove(true);
          case "lang":
            body.append("game_lang", obj.lang);
            return reslove(true);
          case "simulationName":
            body.append("game_name", obj.simulationName);
            return reslove(true);
          case "game_objectives":
            body.append("game_objectives", obj.game_objectives?.length ? encodeURI(obj.game_objectives) : " ");
            return reslove(true);
          case "files":
            obj.files.map((f, i) => {
              body.append("file" + i, f);
            })
            return reslove(true);
          case "date":
          case "time":

            body.append("schedule_start_time", `${date} ${time}`);
            return reslove(true);
          case "game_colors":
            body.append("game_colors", `${obj?.game_colors}`);
            return reslove(true);
          case "game_logo":
            body.append("logo", obj?.game_logo.file);
            body.append("game_colors", `${obj?.game_colors}`);
            return reslove(true);
          case "client_id":
            body.append("client_id", obj?.client_id);
            return reslove(true);
          case "game_time_zone":
            body.append("game_time_zone", obj?.game_time_zone);
            body.append("schedule_start_time", `${date} ${time}`);
            return reslove(true);
        }
      });
      reject(false);
    });
  };
  const editGame = (obj, type) => {
    return new Promise((reslove, reject) => {
      const url = `${URL_PATH}${EDIT_GAME_PATH}`;
      const body = new FormData();
      body.append("id", obj.id);
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      addBodyToEdit(body, obj, type).then((result) => {
        if (result) {
          const requedtOptions = { method: "post", body: body };
          try {
            fetch(url, requedtOptions)
              .then((res) => res.text())
              .then((res) => {
                res = JSON.parse(res);
                if (res.status === "true") {
                  setTimeout(() => {
                    getUpdateData();
                  }, 1000);
                  reslove(true);
                  props.sentMessageWebSocket("game_info", "all");
                } else {
                  reslove(false);
                }
              });
          } catch (e) { }
        }
      }).catch(() => reject(false));
    })
  };

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  const addNewGame = (obj) => {
    return new Promise((reslove, reject) => {
      const url = `${URL_PATH}${ADD_NEW_GAME}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      body.append("is_demo", obj.isDemo ? "on" : "0");
      body.append("game_name", encodeURI(obj.name));
      body.append("game_type", obj.type);
      body.append("game_time_zone", obj.game_time_zone);
      const { date, time } = convertToUTC(obj.time, obj.date, obj.game_time_zone);
      body.append("schedule_start_time", `${date} ${time}`);
      if (obj?.game_colors) {
        body.append("game_colors", obj.game_colors);
      }
      if (obj?.game_logo) {
        body.append("logo", obj.game_logo.file);
      }
      if (obj?.client) {
        body.append("client_id", obj.client);
      }
      const requedtOptions = { method: "post", body: body };
      try {
        fetch(url, requedtOptions)
          .then((res) => res.text())
          .then((res) => {
            try {
              res = JSON.parse(res);
            } catch (e) {

            }

            if (res.status === "true" || res.success === "true") {
              reslove({ id: res?.game_info.id, isDemo: obj.idDemo });
              getGameInfoData();
              props.sentMessageWebSocket("game_info", "all");
            } else {
              reject(false);
            }
          });
      } catch (e) { }
    })
  };

  const checkStartGame = (res) => {
    if (
      !res.game_info?.start_time &&
      sessionStorage.getItem(CREATE_GAME_PERMISSION) !== "1" &&
      res.game_info?.players?.find(player => player.id === sessionStorage.getItem(USER_ID_IN_GAME))?.permission_for_game !== "1"
    ) {
      dispatch(setInGame());
      dispatch(setStartGame(false));
      return true;
    } else {
      if (res.game_info.end_time && (res.game_info?.players?.find(player => player.id === sessionStorage.getItem(USER_ID_IN_GAME))?.permission_for_game !== "1" && sessionStorage.getItem("create_game_permission") !== "1")) {
        // sessionStorage.removeItem(GAME_ID);
        dispatch(end());
        dispatch(setStartGame(false));
        return false
      }
      dispatch(setToken(true));
      dispatch(setStartGame(true));
      return true;
    }
  };
  const addPlayerHandler = (player, deleted) => {
    return new Promise((resolve, reject) => {
      try {
        const url = `${URL_PATH}${SAVE_PLAYER_FOR_GAME}`;
        const body = new FormData();
        body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
        if (player.profile_image?.file) {
          body.append("file0", player.profile_image.file);
        }
        if (deleted) {
          body.append("delete", "1");
          body.append("id", player);
        } else {
          body.append("show_in_chat", "1");
          if (!player.represented) {
            body.append(
              _PERMISSION_FOR_GAME_,
              player.permission_for_game
            );
            body.append(_EMAIL_, player.email);
            if (player.player_observer) {
              body.append(_OBSERVER_NAME_, player.player_observer);
            }
            if (player.player_description) {
              body.append(_PLAYER_DESC_, player.player_description?.length ? encodeURI(player.player_description) : "");
            }
          } else {
            if (player.player_description) {
              body.append(_PLAYER_DESC_, player.player_description?.length ? encodeURI(player.player_description) : "");
            }
          }
          if (player.id != null) {
            body.append("id", player.id);
          }
          if (player.player_image) {
            body.append("profile_image", player.player_image.file);
          }
          if (player.department) {
            body.append("department", player.department)
          }
          body.append(_REPRESENTED_, player.represented);
          body.append(_NAME_FOR_GAME_, player.name_for_game);
          console.log(body.get(_NAME_FOR_GAME_));
        }
        const requestOptions = {
          method: "POST",
          body: body,
        };
        fetch(url, requestOptions)
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res?.error?.indexOf("ok") >= 0) {
              dispatch(openRealPlayer("", false));
              getUpdateData();
              props.sentMessageWebSocket("game_info", "all");
              resolve(res);
            } else {
              if (res?.error?.indexOf("17") >= 0) {
                dispatch(setErrorMessage(true, "", t("add_player_name_err3"), false, () => {
                  // window.location.href = '/';
                }));
              }
              reject(false);
            }
          })
          .catch((e) => { reject(false) });
      } catch (e) { reject(false) }
    })
  };

  const addCountDownVideo = (file) => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${COUNT_DOWN_VIDEO}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      if (file.type.includes('video')) {
        body.append(_VIDEO_, file);
      } else {
        body.append(_FILE0_, file);
      }
      fetch(url, {
        method: "POST",
        body: body
      }).then((res) => res.text())
        .then((res) => {
          res = JSON.parse(res);
          if (res.upload_status !== "false") {
            props.sentMessageWebSocket("game_info", "all");
            resolve(true);
            getUpdateData();
          } else {
            reject(res);
          }
        }).catch((err) => reject(err))
    })
  };

  const removeCountdownVideoData = () => {
    const url = `${URL_PATH}${REMOVE_COUNTDOWN_VIDEO}`;
    const body = new FormData();
    body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
    fetch(url, {
      method: "POST",
      body: body
    }).then((res) => res.text())
      .then((res) => {
        res = JSON.parse(res);
        if (res.removed) {
          props.sentMessageWebSocket("game_info", "all");
          dispatch(removeCountdownVideo());
        }
      }).catch((e) => { console.log(e) })
  }

  const deletePlayerData = (id) => {
    return new Promise((reslove, reject) => {
      if (id) {
        const body = new FormData();
        const url = `${URL_PATH}${REMOVE_PLAYER}`;
        body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
        body.append("player_id", id);
        try {
          fetch(url, {
            method: "POST",
            body: body
          })
            .then((res) => res.text())
            .then((res) => {
              res = JSON.parse(res);
              if (res.player_removed !== "false") {
                reslove(true);
                getUpdateData();
                props.sentMessageWebSocket("game_info", "all");
              } else {
                reject(false);
              }
            }).catch(() => reject(false))
        } catch (e) {
          reject(false);
        }
      } else {
        reject(false);
      }
    })
  }
  const invitePlayerData = (id) => {
    return new Promise((reslove, reject) => {
      if (id) {
        const body = new FormData();
        const url = `${URL_PATH}${RESEND_INVITE}`;
        body.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
        body.append("to_list", id);
        try {
          fetch(url, {
            method: "POST",
            body: body
          })
            .then((res) => res.text())
            .then((res) => {
              res = JSON.parse(res);
              if (res?.error?.indexOf("ok") >= 0) {
                reslove(true);
              } else {
                reject(false);
              }
            }).catch(() => {
              reject(false);
            })
        } catch (e) {
          reject(false);
        }
      } else {
        reject(false);
      }
    })
  }

  const deleteBackgroundFile = (id) => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${DELETE_DOC_PATH}`;
      const body = new FormData();
      body.append(_F_PHPSESSID_, sessionStorage.getItem(
        F_PHPSESSID
      ))
      body.append(_ID_, id)
      try {
        fetch(url, { method: "POST", body: body })
          .then((res) => res.text())
          .then((res) => {
            res = JSON.parse(res);
            if (res.deleted === "true") {
              props.sentMessageWebSocket("game_info", "all");
              dispatch(removeBackgroundFile(id)).then(() => {
                resolve(true);
              }).catch(() => {
                resolve(false);
              })
            }
          }).catch(() => {
            resolve(false);
          })
      } catch (e) { }
    })
  }

  const getClientsUser = () => {
    return new Promise((resolve, reject) => {
      const url = `${URL_PATH}${GET_CLIENT_USER}`;
      const fromData = new FormData();
      fromData.append(_F_PHPSESSID_, sessionStorage.getItem(F_PHPSESSID));
      fetch(url, {
        body: fromData,
        method: "POST",
      }).then((res) => res.json())
        .then((res) => {
          dispatch(setClientsForUser(res?.client_list));
        }).catch(() => {
        })
    })
  }

  useEffect(() => {
    if (sessionStorage.getItem(F_PHPSESSID)) {
      getClientsUser();
    }
  }, [sessionStorage.getItem(F_PHPSESSID)]);

  useEffect(() => {
    if (props.loginType !== "in") {
      dispatch(setGameInfo(false));
    } else if (sessionStorage.getItem(GAME_ID)) {
      getGameInfoData();
    };
  }, [props.loginType]);

  useEffect(() => {
    if (props.typeRefresh === "game_info" && sessionStorage.getItem(GAME_ID)) {
      getGameInfoData();
    };
  }, [props.typeRefresh]);

  return (
    <GameInfoContext.Provider
      value={{
        getGameInfoData,
        editGame,
        addNewGame,
        addPlayerHandler,
        addCountDownVideo,
        deletePlayerData,
        invitePlayerData,
        deleteBackgroundFile,
        removeCountdownVideoData,
        getClientsUser
      }}
    >
      {props.children}
    </GameInfoContext.Provider>
  );
}
