import {
  View,
  Text,
  Pressable,
  StyleSheet,
  Image,
  ScrollView,
  TextInput,
} from "react-native";
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { l, t } from "i18n-js";
import EventSelect from "./EventSelect";
import * as DocumentPicker from "expo-document-picker";
import UseResponsiveSize from "../../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../../modules/UseResponsiveScreen";
import { useRef } from "react";
import { setErrorMessage, showFile } from "../../../../../redux/actions";
import { getContent } from "../../../../modules/getContent";
import SearchInput from "../../../../Elements/SearchInput";
import { FA5Style } from "@expo/vector-icons/build/FontAwesome5";
import FileLimitPopup from "../../../../Elements/FileLimitPopup";
import { convertByte } from "../../../../modules/ConvertByte";
import { createFileObject } from "../../../../modules/CreateFileObject";
import { MAX_FILES_SIZE } from "@env";
import EditorTiny from "../../../../editor/EditorTiny";

export default function SocialAction({
  checkActionFields,
  isEditing,
  inject,
  setActionPost,
  isCompletedForm,
  platform_type,
  editAction,
  setAction,
  isPossibletoClose,
  saveRef,
  moveToPlayers,
  newAction
}) {
  const { width, height } = UseResponsiveSize();
  const { heightS, widthS } = UseResponsiveScreen();

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, background, color, direction);

  const dispatch = useDispatch();

  const images = useSelector((state) => state.style.images);
  const players = [...useSelector((state) => state.players.realPlayers), ...useSelector((state) => state.players.repPlayers)];


  const postRef = useRef(null);
  const photoRef = useRef(null);
  const videoRef = useRef(null);
  const [showFileLimit, setShowFileLimit] = useState({ photo: false, video: false });

  const [social, setSocial] = useState({
    to_list: "",
    to_list_ids: "",
    from: "",
    ...(inject?.inject || inject),
    body: inject?.inject?.message_body || inject?.message_body || "",
  });
  const [fromPlayers, setFromPlayers] = useState([...players]);
  const [toPlayers, setToPlayers] = useState([...players]);
  const [inputHeight, setInputHeight] = useState(heightS * 70);
  const [imageRatio, setImageRatio] = useState();
  const [mainWidth, setMainWidth] = useState();
  const [fromErr, setFromErr] = useState(false);
  const [err, setErr] = useState("");

  let isCompletedSocialForm =
    social.from && social.to_list.length > 0 && social.body && isCompletedForm
      ? true
      : false;

  // const addAllPlayers = () => {
  //   let to_list = "";
  //   let to_list_ids = "";
  //   toPlayers.forEach((e, i) => {
  //     to_list += `${e.name_for_game},`;
  //     to_list_ids += `${e.id},`;
  //   });
  //   setSocial({ ...social, to_list: to_list, to_list_ids: to_list_ids });
  //   setAction({ ...inject.inject, to_list: to_list, to_list_ids: to_list_ids });
  // };

  const openFilesBrowser = (
    setValue,
    type = "image/png, image/jpeg, audio/mp3, video/mp4",
    typeFile
  ) => {
    DocumentPicker.getDocumentAsync({
      type: type,
      allowsEditing: true,
      aspect: [4, 3],
    }).then((res) => res.assets[0]).then((res) => {
      let size = convertByte(res.size);
      if (size > MAX_FILES_SIZE) {
        isPossibletoClose.current = false;
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_fileSize_mail")}`,
            false,
            () => isPossibletoClose.current = true
          )
        );
        return;
      }
      if (type.indexOf(res.mimeType) < 0) {
        isPossibletoClose.current = false;
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t(typeFile === "img" ? "err_images_social" : "err_videos_social")}`,
            false,
            () => isPossibletoClose.current = true
          )
        );
        return;
      }
      Image.getSize(res.uri, (width, height) => setImageRatio(height / width));
      setValue(res);
    });
  };

  const checkFields = () => {
    let errText = err;
    if (!social.from_id.length > 0) {
      if (errText.indexOf("from,") < 0) {
        errText += "from,";
      }
    } else {
      errText = errText.replace("from,", "");
    }
    if (!social.to_list_ids.length > 0) {
      if (errText.indexOf("to,") < 0) {
        errText += "to,";
      }
    } else {
      errText = errText.replace("to,", "");
    }
    if (social.body == "") {
      if (errText.indexOf("body,") < 0) {
        errText += "body,";
      }
    } else {
      errText = errText.replace("body,", "");
    }
    setErr(errText);
    if (errText.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  const shareWith = (
    <View style={[styles.rightActionInputContainer, { zIndex: 4 }]}>
      <Text
        style={[
          styles.rightActionInputLabel,
          {
            color: color.infoHeaderNavText,
          },
        ]}
      >{t("share_with")}</Text>
      <SearchInput
        playersList={toPlayers}
        styleType={{ dark: true, type: "to", check: true }}
        value={social}
        setValue={(post) => { setSocial({ ...social, to_list: post.to_list, to_list_ids: post.to_list_ids }); }}
        //system={type !== "mail" && playerPermission !== "1"}
        err={err}
        styles={styles}
        isEditing={isEditing}
      />
    </View>
  );

  const postAttachment = (
    <View
      style={[

        styles.socialAddFileContainer,
        {
          paddingHorizontal:
            social?.file || !isEditing ? 0 : widthS * 10,
          paddingBottom:
            social?.file || !platform_type ? 0 : heightS * 10,
          paddingTop: !platform_type ? heightS * 10 : 0,
        }, !social.file && { paddingHorizontal: "1.5vw", marginTop: "1vw" }
      ]}
    >
      {!social.file ? (
        <>
          <Pressable
            ref={photoRef}
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: widthS * 5
            }}
            onPress={() =>
              openFilesBrowser((res) => {
                setSocial((social) => {
                  return { ...social, file: res };
                });
              }, "image/png, image/jpeg", "img")
            }
            onHoverIn={() => { setShowFileLimit({ ...showFileLimit, photo: true }) }}
            onHoverOut={() => { setShowFileLimit({ ...showFileLimit, photo: false }) }}
          >
            <Image
              source={{ uri: images.filePhotoIcon }}
              style={styles.newsPhotoIcon}
              resizeMode="contain"
            />
            <Text
              style={[
                styles.newsAddPhotoText,
                {
                  ...(direction === "rtl" ? { paddingLeft: widthS * 15, } : { paddingRight: widthS * 15, }),
                  color: color.infoHeaderNavText,
                },
              ]}
            >{`${t("add_photo")}`}</Text>
            {showFileLimit.photo && (
              <FileLimitPopup
                refObj={photoRef}
              />
            )}
          </Pressable>
          <Pressable
            ref={videoRef}
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              gap: widthS * 5
            }}
            onPress={() =>
              openFilesBrowser((res) => {
                setSocial((social) => {
                  return { ...social, file: res };
                });
              }, "video/mp4", "video")
            }
            onHoverIn={() => { setShowFileLimit({ ...showFileLimit, video: true }) }}
            onHoverOut={() => { setShowFileLimit({ ...showFileLimit, video: false }) }}
          >
            <Image
              source={{ uri: images.fileVideoIcon }}
              style={styles.newsPhotoIcon}
              resizeMode="contain"
            />
            <Text
              style={[
                styles.newsAddPhotoText,
                {
                  color: color.infoHeaderNavText,
                },
              ]}
            >{`${t("add_video")}`}</Text>
            {showFileLimit.video && (
              <FileLimitPopup
                refObj={photoRef}
              />
            )}
          </Pressable>
        </>
      ) : (
        <View
          style={{ width: "100%", height: "300px" }}
          onPress={() => {
            dispatch(
              showFile({
                name: social.file.name,
                type: social.file.type,
                link: social.file,
              })
            )
          }
          }
        >
          {(isEditing || !platform_type) && (
            <Pressable
              style={[styles.btn_image, { backgroundColor: color.infoBorder }]}
              onPress={() =>
                setSocial((social) => {
                  return { ...social, file: "" };
                })
              }
            >
              <Text style={{ color: color.buildTitle, fontSize: widthS * 8 }}>
                X
              </Text>
            </Pressable>
          )}
          {social.file.mimeType === "video/mp4" || social.file?.type === "/mp4" ? (
            <video
              style={{
                width: "100%",
                objectFit: "fill",
              }}
              controls
            >
              <source src={social.file?.uri ? social.file.uri : social.file?.link} />
            </video>
          ) : (
            <Image
              source={{ uri: social.file?.uri ? social.file.uri : social.file?.link }}
              style={[
                styles.socialImage,
                {
                  height: Math.min(mainWidth * imageRatio, heightS * 500),
                },
              ]}
              resizeMode="cover"
            />
          )}
        </View>
      )}
    </View>
  );

  useEffect(() => {
    isEditing && setInputHeight(5);
  }, [isEditing]);

  useEffect(() => {
    setSocial({
      ...social,
      ...(inject?.inject || inject),
      body: inject?.inject?.body || inject?.body || "",
      file: inject.inject.file ? inject.inject.file : null
    });
    () => {
      setSocial({
        ...social,
        ...(inject?.inject || inject),
        body: inject?.inject?.body || inject?.body || "",
      });
    }
  }, []);

  // useEffect(() => {
  //   if (!existAction) {
  //    addAllPlayers();
  //   }
  // }, [players.length, existAction]);

  useEffect(() => {
    if (inputHeight === 5) {
      setInputHeight(postRef.current?.scrollHeight);
    }
  }, [inputHeight]);

  useEffect(() => {
    setToPlayers([...players].filter((player) => player.id !== social.from_id));
  }, [social.from_id]);

  useEffect(() => {
    const to = social.to_list_ids.split(",");
    setFromPlayers(
      [...players].filter((player) => !to.find((to) => to.trim() === player.id))
    );
  }, [social.to_list_ids]);

  useEffect(() => {
    Image.getSize(social?.file, (width, height) =>
      setImageRatio(height / width)
    );
  }, [social?.file]);

  useEffect(() => {
    if (saveRef.current) {
      if (checkFields() && checkActionFields()) {
        setActionPost(social, "", true).then(() => moveToPlayers()).catch(() => moveToPlayers());;
        saveRef.current = false;
      } else {
        saveRef.current = false;
      }
    }
  }, [saveRef.current, isEditing]);

  return (
    <View
      style={styles.bodyC}
    // showsVerticalScrollIndicator={false}
    // contentContainerStyle={[styles.rightActionInputs]}
    // onLayout={(event) => setMainWidth(event.nativeEvent.layout.width)}
    >
      <View style={{ flex: 1, justifyContent: "center" }}>
        {isEditing || !platform_type ? (
          <>
            <ScrollView
              style={[{ zIndex: 2 },]}
              nativeID='mail-players'
              contentContainerStyle={[(isEditing || newAction || !platform_type) && [
                styles.previewPostMain(true),
                isEditing && {
                  backgroundColor: color.infoBorder,
                  marginTop: "1vw",
                },
              ]]}
            >
              <View style={[isEditing && styles.postBody, { zIndex: 3 }]}>
                {err?.indexOf("from") >= 0 && social.from_id?.length < 1 && <Text style={styles.errText}>{t("social_err_from")}</Text>}
                <View style={[styles.rightActionInputContainer, { zIndex: 5 }]}>
                  <Text
                    style={[
                      styles.rightActionInputLabel,
                      {
                        color: color.infoHeaderNavText,
                      },
                    ]}
                  >{`${t("from")}`}</Text>
                  <SearchInput
                    playersList={fromPlayers}
                    styleType={{ dark: true, type: "from", check: false }}
                    value={social}
                    setValue={(post) => { setSocial({ ...social, from: post.from, from_id: post.from_id }); }}
                    //system={type !== "mail" && playerPermission !== "1"}
                    err={err}
                    styles={styles}
                    isEditing={isEditing}
                  />
                </View>
                {shareWith}
                {err?.indexOf("body") >= 0 && social.body?.length < 1 && <Text style={styles.errText}>{t("new_game_err_name")}</Text>}
                <View
                  style={[
                    styles.rightActionInputContainer,
                    { zIndex: 3, alignItems: "flex-start" },
                  ]}
                >
                  <Text
                    style={[
                      styles.rightActionInputLabel,
                      {
                        paddingTop: heightS * 13,

                        color: color.infoHeaderNavText,
                      },
                    ]}
                  >
                    {t("post")}
                  </Text>
                  <View nativeID="social" style={[
                    styles.input,
                    {
                      color: color.infoHeaderNavText,
                      backgroundColor: background.infoBody,
                      maxHeight: "12vw",
                      minHeight: "8vw",
                      flex: 3,
                      overflow: "auto",
                      writingDirection: "ltr",
                      marginLeft: widthS * 7,
                    },
                    err && social.body == "" && styles.mail_error,
                  ]}>
                    <EditorTiny
                      colorSkin={true}
                      placeholder={""}
                      id={"social-editor"}
                      setText={(txt) => {
                        setSocial({ ...social, body: txt });
                      }}
                      refCurrent={postRef}
                      textCurrent={social.body || ""}
                    />
                  </View>
                </View>
              </View>
              {social.file && postAttachment}
            </ScrollView>
            {!social.file && postAttachment}
            <View
              style={[
                styles.newsSaveBtnContainer,
                {
                  borderTopColor: color.infoBorder,
                },
              ]}
            >
              <Pressable
                //disabled={!isCompletedSocialForm}
                onPress={() => {
                  let completed_action = checkActionFields();
                  if (checkFields() && checkActionFields()) {
                    setActionPost(social, "", true);
                  }
                }}
                style={[
                  styles.saveBtn,
                  isCompletedSocialForm
                    ? {
                      backgroundColor: background.infoSaveBtn,
                    }
                    : [
                      styles.saveBtnUncompleted,
                      {
                        borderColor: color.infoHeaderNavText,
                      },
                    ],
                  { margin: 0 },
                ]}
              >
                <Text
                  style={[
                    styles.saveBtn_text,
                    isCompletedSocialForm
                      ? {
                        color: color.text2,
                      }
                      : {
                        color: color.infoHeaderNavText,
                      },
                  ]}
                >
                  {t("save")}
                </Text>
              </Pressable>
            </View>
          </>
        ) : (
          <ScrollView
            showsVerticalScrollIndicator={false}
            nativeID='mail-players'
            contentContainerStyle={[
              styles.previewPostMain(true),
              {
                backgroundColor: color.infoBorder,
              },
            ]}
          >
            <View style={styles.postBody}>
              <View style={styles.playerDetailsContainer}>
                <View
                  style={[
                    styles.imageContainer,
                    {
                      borderColor: color.logoColor,
                      shadowColor: color.logoColor,
                    },
                  ]}
                >
                  <Image
                    style={[styles.icon]}
                    source={{ uri: images.user_image }}
                  />
                </View>
                <View style={styles.playerDetails}>
                  <Text style={[styles.name, { color: color.infoHeaderNavText }]}>
                    {social.from}
                  </Text>
                </View>
              </View>
              {shareWith}
              <Text
                style={[
                  styles.name,
                  {
                    paddingVertical: heightS * 15,
                    color: color.infoHeaderNavText,
                  },
                ]}
              >
                {<div dangerouslySetInnerHTML={{ __html: `<div>${social.body}</div>` }} />}
              </Text>
            </View>
            {social?.file && postAttachment}
          </ScrollView>
        )}
      </View>
    </View>
  );
}

const stylesR = (width, height, heightS, widthS, background, color, direction) =>
  StyleSheet.create({
    bodyC: {
      maxHeight: "27vw",
      marginTop: "2vw",
      paddingHorizontal: "1.5vw",
      writingDirection: direction
    },
    rightActionInputs: {
      minHeight: "100%",
    },
    rightActionInputContainer: {
      flexDirection: "row",
      alignItems: "center",
    },
    rightActionInputLabel: {
      fontSize: widthS * 8,
      flex: 1,
    },
    input: {
      height: heightS * 30,
      fontSize: widthS * 8,
      marginVertical: heightS * 5,
      borderRadius: widthS * 9,
      paddingVertical: 5 * heightS,
      paddingHorizontal: widthS * 10,
      outlineStyle: "none",
    },

    socialAddFileContainer: {
      flexDirection: "row",
      zIndex: 2
    },
    socialImage: {
      width: "100%",
      flex: 1,
    },
    newsAddPhotoText: {
      fontSize: widthS * 8,
    },
    newsPhotoIcon: {
      width: widthS * 10,
      height: widthS * 10,
    },
    newsSaveBtnContainer: {
      marginTop: heightS * 8,
      borderTopWidth: 1,
      paddingVertical: heightS * 15,
      alignItems: "flex-end",
      marginHorizontal: "1.5vw",
    },
    saveBtn: {
      paddingHorizontal: widthS * 8,
      paddingVertical: heightS * 4,
      borderRadius: widthS * 5,
      alignItems: "center",
      justifyContent: "center",
      marginRight: widthS * 10,
    },
    saveBtn_text: {
      fontSize: 10 * widthS,
    },

    saveBtnUncompleted: {
      borderWidth: 1,
    },
    previewPostMain: (flag) => ({
      // borderTopRightRadius: widthS * 10,
      // borderTopLeftRadius: widthS * 10,
      marginHorizontal: flag ? "1.5vw" : "",
      paddingHorizontal: !flag ? "1.5vw" : "",
      borderRadius: widthS * 10,
    }),
    imageContainer: {
      borderRadius: "50%",
      borderWidth: widthS * 1.5,
    },
    icon: {
      width: widthS * 20,
      height: widthS * 20,
      borderRadius: "50%",
    },
    playerDetailsContainer: {
      alignItems: "center",
      flexDirection: "row",
    },
    playerDetails: {
      paddingLeft: widthS * 7,
      justifyContent: "center",
    },

    name: {
      fontSize: widthS * 10,
      lineHeight: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    postBody: {
      paddingHorizontal: widthS * 10,
      paddingVertical: heightS * 12,
    },
    btn_image: {
      position: "absolute",
      right: widthS * 5,
      top: heightS * 5,
      borderRadius: "50%",
      width: widthS * 15,
      height: widthS * 15,
      alignItems: "center",
      justifyContent: "center",
      zIndex: 3,
    },
    search_bar_pressable: {
      flex: 1,
      borderRadius: "10px",
      marginVertical: heightS * 5,
      marginLeft: widthS * 5,
      flexDirection: "inherit",
    },
    list_item: {
      zIndex: 13,
    },
    list_item_text: {
      zIndex: 13,
      margin: widthS * 3,
      marginLeft: widthS * 7,
      fontSize: widthS * 8,
      fontFamily: "Catamaran_400Regular",
      display: "flex",
      alignItems: "center",
    },
    label_text_input: {
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
      //maxWidth: "96%",
      lineHeight: heightS * 15,
      maxHeight: heightS * 20,
      zIndex: 13,
      //margin: widthS * 3,
      marginLeft: widthS * 7,
      display: "flex",
      //alignItems: "center",
    },
    _btn: {
      borderRadius: widthS * 12,
      display: "flex",
      flexDirection: "column",
      zIndex: 3,
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    _btn_open: {
      borderTopLeftRadius: widthS * 5,
      borderTopRightRadius: widthS * 5,
      display: "flex",
      flexDirection: "column",
      zIndex: 3,
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    _btn_head: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minHeight: heightS * 15,
      alignItems: "center",
    },
    _btn_body: {
      width: "100%",
      borderBottomLeftRadius: widthS * 5,
      borderBottomRightRadius: widthS * 5,
      borderTopWidth: 1,
      shadowColor: "black",
      shadowOffset: { width: 0, height: 1 },
      shadowOpacity: 0.2,
      shadowRadius: 3,
      elevation: 10,
    },
    shadow: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.8,
      shadowRadius: 3,
    },
    popup_list_content: {
      // backgroundColor: "#ebebeb",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      borderRadius: widthS * 9,
    },
    popup_list: {
      maxHeight: heightS * 100,
      minHeight: heightS * 20,
      zIndex: 100,
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: widthS * 5,
    },
    errText: {
      color: color.infoSaveBtn,
      fontSize: "0.8vw",
      fontFamily: "Catamaran_400Regular",
      textAlign: "end"
    },
  });
