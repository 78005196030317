import React, { memo, forwardRef, useImperativeHandle, useRef, useState, useCallback, useEffect } from 'react';
import { Tooltip } from 'antd';
import { StyleSheet, View, Text } from 'react-native';

const FullTextTooltip = memo(forwardRef(({ children, text, length, style }, ref) => {
    const [isOverflowed, setIsOverflowed] = useState(false);
    const textRef = useRef(null);

    const checkOverflow = useCallback(() => {
        if (textRef.current) {
            const { clientWidth, scrollWidth } = textRef.current;
            const isOverflow = scrollWidth > clientWidth || (length && children.props.children.length > length);
            setIsOverflowed(isOverflow);
        }
    }, [textRef.current, text, length, children.props.children]);

    useEffect(() => {
        if (textRef.current) {
            checkOverflow();
        }
    }, [children, text, checkOverflow]);

    // שימוש ב-`useImperativeHandle` כדי לחשוף פעולות נוספות דרך ה-ref במידת הצורך
    useImperativeHandle(ref, () => ({
        get textRef() {
            return textRef.current;
        }
    }), []);

    return (
        <Tooltip
            title={isOverflowed && text}
            // style={{ maxHeight: "18em", overflow: "auto" }}
            overlayStyle={{ whiteSpace: 'pre-line', maxHeight: "18em", overflow: "auto" }}
            overlayInnerStyle={{ fontSize: "1.1em", fontWeight: "600" }}
            mouseLeaveDelay={0.1}
            zIndex={10000}
            placement='top'
        >
            <View style={[styles.textContainer, style]}>
                {React.cloneElement(children, { ref: textRef })}
            </View>
        </Tooltip>
    );
}));

const styles = StyleSheet.create({
    textContainer: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
});

export default FullTextTooltip;
