import React, { useEffect, useRef, useState, useContext, useMemo } from "react";
import {
  StyleSheet,
  View,
  Pressable,
  Text,
  TextInput,
  ScrollView,
  Image,
  Platform,
} from "react-native";
import SearchInput from "../../../../Elements/SearchInput";
import * as DocumentPicker from "expo-document-picker";
import { useSelector, useDispatch } from "react-redux";
import ListPlayers from "../../../mailbox/ListPlayers";
import { t } from "i18n-js";
import { listenerAddBT } from "../../../../../AddListener";
import UseResponsiveSize from "../../../../modules/UseResponsiveSize";
import UseResponsiveScreen from "../../../../modules/UseResponsiveScreen";
import ShortenWord from "../../../../modules/ShortenWord";
import EventSelect from "./EventSelect";
import EditorTiny from "../../../../editor/EditorTiny";
import { convertByte } from "../../../../modules/ConvertByte";
import { MAX_FILES_SIZE } from "@env";
import { AllowFilesUploaded } from "../../../../Elements/AllowFilesUploaded";
import {
  setErrorMessage,
  showFile,
  loading,
} from "../../../../../redux/actions";
import FileLimitPopup from "../../../../Elements/FileLimitPopup";
import { AntDesign, Entypo } from "@expo/vector-icons";

export default function EmailAction({
  checkActionFields,
  messageType,
  isEditing,
  inject,
  setActionPost,
  isCompletedForm,
  platform_type,
  isPossibletoClose,
  createNextEventHandler,
  setIsEditing,
  saveRef,
  moveToPlayers,
  closePopup
}) {
  const { width, height } = UseResponsiveSize();
  const { widthS, heightS } = UseResponsiveScreen();

  const background = useSelector((state) => state.style.background);
  const color = useSelector((state) => state.style.color);
  const lang = useSelector((state) => state.setting.setting.lang);
  const direction = useMemo(() => lang === "he" ? "rtl" : "ltr", [lang]);
  const styles = stylesR(width, height, heightS, widthS, background, color);

  const toPlayersTextRef = useRef(null);
  const ccPlayersTextRef = useRef(null);
  const bccPlayersTextRef = useRef(null);
  const widthKeyRef = useRef(null);


  const dispatch = useDispatch();
  const images = useSelector((state) => state.style.images);
  const players = [...useSelector((state) => state.players.realPlayers), ...useSelector((state) => state.players.repPlayers)];
  const [widthKey, setWidthKey] = useState("");
  const [newMessage, setNewMessage] = useState({
    to_list: "",
    to_list_ids: "",
    cc_list: "",
    cc_list_ids: "",
    bcc_list: "",
    bcc_list_ids: "",
    subject: "",
    body: null,
    bodyText: "",
    has_attachments: "0",
    file: [],
    message_parent_id: "",
    time: new Date().toString().slice(4),
    ...(inject?.inject || inject),
  });


  const [showCc, setShowCc] = useState(inject?.mail?.cc_list);
  const [showBcc, setShowBcc] = useState(inject?.mail?.bcc_list);
  const [isEllipsisTo, setIsEllipsisTo] = useState(false);
  const [isEllipsisCc, setIsEllipsisCc] = useState(false);
  const [err, setErr] = useState("");
  const [widthInput, setWidthInput] = useState({
    to: widthS * 10,
    cc: widthS * 10,
  });
  const [inputSearch, setInputSearch] = useState({ to: "", cc: "", bcc: "" });
  const [fromPlayers, setFromPlayers] = useState([...players].filter(player => player.permission_for_game !== "1"));
  const [toPlayers, setToPlayers] = useState([...players].filter(player => player.permission_for_game !== "1"));
  const [showFileLimit, setShowFileLimit] = useState(false);
  const fileRef = useRef(null);

  let isCompletedMaillForm =
    newMessage.from &&
      newMessage.to_list.length > 0 &&
      newMessage.to_list_ids.length > 0 &&
      ((inject.inject.type_action !== "cfaChallenges" && newMessage.body) || inject.inject.type_action === "cfaChallenges") &&
      isCompletedForm
      ? true
      : false;

  const createFilesArray = async (res) => {
    return [] = await Promise.all(Object.keys(res.output).map(async (key) => {
      let size = convertByte(res.output[key].size);
      if (!AllowFilesUploaded.find((e) => res.output[key].type.indexOf(e) >= 0)) {
        isPossibletoClose.current = false;
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_files")}`,
            false,
            () => isPossibletoClose.current = true
          )
        );
        return;
      } else if (size > MAX_FILES_SIZE) {
        isPossibletoClose.current = false;
        dispatch(
          setErrorMessage(
            true,
            undefined,
            `${t("err_fileSize_mail")}`,
            false,
            () => isPossibletoClose.current = true
          )
        );
        return;
      }
      return createUri(res.output[key]).then((uri) => {
        let newFile = {
          name: res.output[key].name,
          type: res.output[key].type,
          uri: uri,
          file: res.output[key],
          size: res.output[key].size
        }
        return newFile;
      }).catch((err) => {
        dispatch(loading(false));
      });
    }));
  }

  const createUri = (file) => {
    return new Promise((reslove, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        reslove(reader.result);
      }
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(file);
    });
  }
  const openFilesBrowser = async () => {
    DocumentPicker.getDocumentAsync({
      multiple: true,
      allowsEditing: true,
      type: AllowFilesUploaded.join(","),
      aspect: [4, 3],
    }).then((res) => {
      createFilesArray(res).then((arr) => {
        dispatch(loading(false));
        arr = arr.filter(e => e);
        setNewMessage({
          ...newMessage,
          file: newMessage.file ? [...arr, ...newMessage.file] : [...arr],
          has_attachments: `${arr.length || 0}`,
        });
      });
    });
  };

  const sortNames = () => {
    return function (a, b) {
      if (a.name_for_game < b.name_for_game) {
        return -1;
      }
      if (a.name_for_game > b.name_for_game) {
        return 1;
      }
      return 0;
    }
  }

  const checkFields = () => {
    let errText = err;
    if (!newMessage.from_id || !newMessage.from.length > 0) {
      if (errText.indexOf("from,") < 0) {
        errText += "from,";
      }
    } else {
      errText = errText.replace("from,", "");
    }
    if (
      !newMessage.to_list_ids ||
      (newMessage.to_list_ids && newMessage.to_list_ids.length <= 0)
    ) {
      if (errText.indexOf("to,") < 0) {
        errText += "to,";
      }
    } else {
      errText = errText.replace("to,", "");
    }
    if (!newMessage?.body?.length > 0) {
      if (errText.indexOf("body,") < 0) {
        errText += "body,";
      }
    } else {
      errText = errText.replace("body,", "");
    }
    if (!newMessage?.subject?.length > 0) {
      if (errText.indexOf("subject,") < 0) {
        errText += "subject,";
      }
    } else {
      errText = errText.replace("subject,", "");
    }
    setErr(errText);
    if (errText.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  const disabeldEditor = () => {
    if (inject.inject.type_action === "cfaChallenges" || (!isEditing && platform_type)) {
      return true;
    }
    return false;
  }
  const fileItem = (file, i) => (
    <View
      style={{
        borderRadius: "0.5vw",
        width: "fit-content",
        borderWidth: "0.1vw",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: "0.5vw",
        borderColor: color.mailBorder2,
        flax: "1",
        margin: "0.1vw"
      }}
    >
      <Pressable
        style={[

          {
            color: color.logoColor,
            justifyContent: "center"
          },
        ]}
        onPress={() => {
          let temp = [...newMessage.file];
          let ids_to_remove = newMessage?.files_to_remove ? newMessage?.files_to_remove : "";
          ids_to_remove += `${temp[i]?.id}, `;
          temp.splice(i, 1);
          setNewMessage({ ...newMessage, file: [...temp], files_to_remove: ids_to_remove });
        }}
      >
        <AntDesign name="close" style={styles.label_text_input} color={color.logoColor} />
      </Pressable>
      <Pressable
        style={{
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
          justifyContent: "center"
        }}
        onPress={() => {
          isPossibletoClose.current = false;
          dispatch(loading(true));
          dispatch(
            showFile({
              name: file.name,
              link: file.uri || file.link,
              type: file?.mimeType || file.type,
            })
          )
        }
        }
      >
        <Text
          numberOfLines={1}
          selectable={false}
          style={[
            styles.label_text_input,
            {
              direction: lang === "he" ? "rtl" : "ltr",
              color: color.logoColor,
              maxWidth: width * 85,
            },
          ]}
        >
          {ShortenWord(file.name, 27)}
        </Text>
      </Pressable>
    </View>
  )

  useEffect(() => {
    setWidthKey(widthKeyRef.current?.offsetWidth + 5)
  }, [widthKeyRef.current])
  useEffect(() => {
    if (newMessage?.to_list?.length > 0) {
      setWidthInput({ ...widthInput, to: widthS * 20 });
    } else {
      setWidthInput({ ...widthInput, to: "100%" });
    }
  }, [width, newMessage?.to_list]);

  useEffect(() => {
    if (newMessage?.cc_list?.length > 0) {
      setWidthInput({ ...widthInput, cc: widthS * 20 });
    } else {
      setWidthInput({ ...widthInput, cc: "100%" });
    }
  }, [width, newMessage?.cc_list]);

  useEffect(() => {
    if (newMessage?.bcc_list?.length > 0) {
      setWidthInput({ ...widthInput, bcc: widthS * 20 });
    } else {
      setWidthInput({ ...widthInput, bcc: "100%" });
    }
  }, [width, newMessage?.bcc_list]);

  useEffect(() => {
    if (!newMessage.to_list_ids.replaceAll(" ", "").replaceAll(",", "").length > 0) {
      setNewMessage({ ...newMessage, to_list_ids: "", to_list: "" })
    }
  }, [newMessage.to_list_ids]);

  useEffect(() => {
    setToPlayers(
      [...players].filter((player) => player.permission_for_game !== "1" && player.id !== newMessage?.from?.id)
    );
  }, [newMessage?.from?.id]);

  useEffect(() => {
    const to = newMessage.to_list_ids.split(",");
    setFromPlayers(
      [...players].filter((player) => player.permission_for_game !== "1" && !to.find((to) => to.trim() === player.id))
    );
  }, [newMessage.to_list_ids]);

  const displayBody = (message, Platform) => {
    return (
      <View style={styles.body_content}>
        <Text selectable={true} style={[styles.body_text,]}>
          {Platform.OS === "web" ? (
            message && typeof message.body === "string" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: message && message.body,
                }}
              />

            ) : (
              message && message.body
            )
          ) : (
            <WebView
              style={styles.container}
              selectable={true}
              originWhitelist={["*"]}
              source={{
                html: message && message.body,
              }}
            />
          )}
        </Text>
      </View>
    )
  }

  useEffect(() => {
    if (saveRef.current) {
      let completed_action = checkActionFields();
      if (checkFields() && completed_action && isCompletedMaillForm) {
        setActionPost(newMessage, "", false).then(() => moveToPlayers()).catch(() => moveToPlayers());
        saveRef.current = false;
      } else {
        saveRef.current = false;
      }
    }
  }, [saveRef.current, isEditing]);

  return (
    <View style={[styles.container, { marginTop: platform_type ? "2vw" : 0, paddingHorizontal: "3vw", direction: direction }]}>
      <View style={[styles.main, { backgroundColor: background.mailBody }]}>
        {(isEditing || !platform_type) ?
          <View
            style={[{
              maxHeight: createNextEventHandler ? "25vw" : "30vw",
              height: createNextEventHandler ? "25vw" : "30vw",
              flex: 1,
              minHeight: createNextEventHandler ? "25vw" : "26vw",
              height: "-webkit-fill-available",
              paddingBottom: heightS * 5,
              paddingHorizontal: "1.5vw",
            }]}
          >
            {err?.indexOf("from,") >= 0 && newMessage?.from_id?.length < 1 && <Text style={styles.errText}>{t("email_err_from")}</Text>}
            <View style={styles.from_view}>
              <Text
                selectable={false}
                ref={widthKeyRef}
                style={[styles.label_for, { minWidth: `${widthKey}px` }]}
              >
                {t("from")}
              </Text>
              <SearchInput
                newMail={true}
                playersList={fromPlayers}
                styleType={{ dark: false, type: "from", check: false }}
                value={newMessage}
                setValue={(message) => { setNewMessage({ ...newMessage, from: message.from, from_id: message.from_id }); }}
                err={err}
                styles={styles}
                isEditing={isEditing}
              />
            </View>
            {err?.indexOf("to,") >= 0 && newMessage.to_list_ids?.length < 1 && <Text style={styles.errText}>{t("email_err_to")}</Text>}
            <View style={[styles.to_view, (!showBcc && newMessage.bcc_list?.length === 0) && (!showCc && newMessage.cc_list?.length === 0) && styles.borderBottomHeader]}>
              <View style={styles.to_view_child}>
                <Text
                  selectable={false}
                  style={[styles.label_for, { minWidth: `${widthKey}px` }]}
                >
                  {t("to")}
                </Text>
                <SearchInput
                  newMail={true}
                  playersList={toPlayers}
                  styleType={{ dark: false, type: "to", check: true }}
                  value={newMessage}
                  setValue={(message) => { setNewMessage({ ...newMessage, to_list: message.to_list, to_list_ids: message.to_list_ids }); }}
                  err={err}
                  styles={styles}
                  isEditing={isEditing}
                />
                {!(platform_type && !isEditing) && (
                  <View style={styles.copy}>
                    {(newMessage.cc_list?.length === 0 && !showCc) &&
                      <Pressable
                        disabled={!isEditing && platform_type}
                        onPress={() => {
                          if (showCc)
                            setNewMessage({
                              ...newMessage,
                              cc_list: "",
                              cc_list_ids: "",
                            });
                          setShowCc((prev) => !prev);
                        }}
                      >
                        <Text
                          style={[
                            styles.copyText,
                          ]}
                        >
                          {t("cc")}
                        </Text>
                      </Pressable>}
                    {(newMessage.bcc_list?.length === 0 && !showBcc) &&
                      <Pressable
                        disabled={!isEditing && platform_type}
                        onPress={() => {
                          if (showBcc)
                            setNewMessage({
                              ...newMessage,
                              bcc_list: "",
                              bcc_list_ids: "",
                            });
                          setShowBcc((prev) => !prev);
                        }}
                      >
                        <Text
                          style={[
                            styles.copyText,
                            styles.bcc,
                          ]}
                        >
                          {t("bcc")}
                        </Text>
                      </Pressable>}
                  </View>
                )}
              </View>
            </View>
            {(showCc || newMessage.cc_list) && (
              <View style={[styles.cc_view, !showBcc && newMessage.bcc_list?.length === 0 && styles.borderBottomHeader]}>
                <Text
                  selectable={false}
                  style={[styles.label_for, { minWidth: `${widthKey}px` }]}
                >
                  {t("cc")}
                </Text>
                <SearchInput
                  newMail={true}
                  playersList={toPlayers}
                  styleType={{ dark: false, type: "cc", check: true }}
                  value={newMessage}
                  setValue={(message) => { setNewMessage({ ...newMessage, cc_list: message.cc_list, cc_list_ids: message.cc_list_ids }); }}
                  err={err}
                  styles={styles}
                  isEditing={isEditing}
                />
              </View>
            )}
            {(showBcc || newMessage.bcc_list) && (
              <View style={styles.bcc_view}>
                <Text
                  selectable={false}
                  style={[styles.label_for, { minWidth: `${widthKey}px` }]}
                >
                  {t("bcc")}
                </Text>
                <SearchInput
                  newMail={true}
                  playersList={toPlayers}
                  styleType={{ dark: false, type: "bcc", check: true }}
                  value={newMessage}
                  setValue={(message) => { setNewMessage({ ...newMessage, bcc_list: message.bcc_list, bcc_list_ids: message.bcc_list_ids }); }}
                  err={err}
                  styles={styles}
                  isEditing={isEditing}
                />
              </View>
            )}
            <View
              style={[styles.subject_view, styles.borderBottomHeader]}
            >
              <Text
                selectable={false}
                style={[styles.label_for]}
              >
                {t("subject")}:
              </Text>
              <TextInput
                style={[
                  styles.inp_subject,
                  {
                    outline: "none",
                    paddingLeft: widthS * 3,
                    direction: lang === "he" ? "rtl" : "ltr",
                    transition: "all 0.3s",
                  },
                  err !== "" && !newMessage.subject ? styles.mail_error : "",
                ]}
                value={newMessage.subject}
                onChangeText={(txt) => setNewMessage({ ...newMessage, subject: txt })}
              />
            </View>
            <ScrollView
              nativeID="new-mail-draft"
              style={{
                flex: 1,
              }}
              contentContainerStyle={[{
                flex: 1,
                // minHeight: "19vw",
                display: "flex",
                flexDirection: "column",
                zIndex: 9,
                paddingTop: heightS * 4,
                minHeight: "99.5%",
                justifyContent: "space-between",
              }, err && !newMessage.body ? styles.mail_error : ""]}
            >
              <View
                style={[{
                  paddingHorizontal: widthS * 10,
                  paddingVertical: heightS * 15,
                  display: "flex",
                }]}
              >
                <EditorTiny
                  disabled={disabeldEditor()}
                  colorSkin={false}
                  colorIcon={"black"}
                  id={"mytoolbar-flow"}
                  messageType={messageType}
                  newMessage={newMessage}
                  actionType={inject.inject.type_action}
                  setImagesBody={(images) => setNewMessage({ ...newMessage, body_images: images })}
                  setNewMessage={(body) => setNewMessage({ ...newMessage, body: body })}
                />
              </View>
              {newMessage.file &&
                (
                  <View
                    nativeID="mail-players"
                    style={{
                      maxHeight: "4vw",
                      height: "4vw",
                      overflowY: "scroll",
                      marginBottom: "0.5vw",
                      paddingHorizontal: "1vw"
                    }}
                    contentContainerStyle={[styles.attach_view]}>
                    <View
                      style={{ flex: 1, flexDirection: "row", flexWrap: "wrap", justifyContent: "flex-end", alignItems: "center" }}
                    >
                      {newMessage.file.map((file, i) => {
                        return (
                          <> {fileItem(file, i)} </>
                        )
                      })}
                    </View>
                  </View>
                )}
            </ScrollView>
            {
              <View
                style={[
                  styles.footer,
                  {
                    borderTopColor: color.mailBorder2,
                    display: isEditing || !platform_type ? "" : "none",
                  },
                ]}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    flex: 1,
                  }}
                >
                  <Pressable
                    ref={fileRef}
                    onPress={openFilesBrowser}
                    style={({ hovered }) =>
                      hovered ? styles.toolbar_btns_hovered : styles.toolbar_btns
                    }
                    onHoverIn={() => { setShowFileLimit(true) }}
                    onHoverOut={() => { setShowFileLimit(false) }}
                  >
                    <Image
                      source={{ uri: images.attachmentIcon }}
                      style={{
                        width: widthS * 9,
                        height: widthS * 9,
                        resizeMode: "contain",
                      }}
                    />
                    {showFileLimit && (
                      <FileLimitPopup
                        refObj={fileRef}
                      />
                    )}
                  </Pressable>
                  <View style={{ flex: 1 }} nativeID={"mytoolbar-flow"} />
                </View>
              </View>
            }
          </View> :
          <ScrollView
            style={[{ maxHeight: heightS * 300, height: heightS * 335, flex: 1, }]}
            nativeID="mail-players"
            contentContainerStyle={[
              {
                minHeight: "26vw",
                height: "-webkit-fill-available",
                paddingBottom: heightS * 5,
                paddingHorizontal: "1.5vw",
              },
            ]}
          >
            {/* top-message */}
            <View
              style={[
                styles.top,
                {
                  borderBottomColor: color.mailBorder,
                  borderBottomWidth: !newMessage ? 0 : widthS * 0.5,
                  direction: lang === "he" ? "rtl" : "ltr",
                },
              ]}
            >
              <Text
                selectable={false}
                numberOfLines={1}
                style={[
                  styles.subject,
                  { direction: lang === "he" ? "rtl" : "ltr" },
                ]}
              >
                {newMessage && newMessage.subject}
              </Text>
              <View style={styles.headers_view}>
                <Text
                  selectable={true}
                  style={[
                    styles.headers,
                    lang === " he"
                      ? { marginLeft: (width / 100) * 3 }
                      : { marginRight: widthS * 3 },
                  ]}
                >
                  {t("from")}:
                </Text>
                <Text selectable={true} numberOfLines={1} style={styles.headers_details}>
                  {newMessage.from}
                </Text>
              </View>
              <View style={[styles.headers_view, { zIndex: 5 }]}>
                <Pressable
                  style={{ flexDirection: "row", cursor: "default" }}
                >
                  {({ hovered }) => (
                    <>
                      <View
                        style={{
                          opacity: hovered && isEllipsisTo ? 0 : 1,
                          flexDirection: "row",
                          transition: "0.2s all",
                        }}
                      >
                        <Text
                          style={[
                            styles.headers,
                            lang === " he"
                              ? { marginLeft: (width / 100) * 3 }
                              : { marginRight: widthS * 3 },
                          ]}
                        >
                          {t("to")}:
                        </Text>
                        <Text
                          ref={toPlayersTextRef}
                          numberOfLines={1}
                          selectable={false}
                          style={styles.headers_details}
                        >
                          {newMessage.to_list ? newMessage.to_list[newMessage.to_list.length - 2] ===
                            ","
                            ? newMessage.to_list.slice(
                              0,
                              newMessage.to_list.length - 2
                            )
                            : newMessage.to_list : newMessage.to_list
                              .split(",")
                              .map(
                                (id) =>
                                  players.find((player) => player.id === id)?.name_for_game
                              ).filter(name => name)
                              .join(",")}
                        </Text>
                      </View>
                      {isEllipsisTo && hovered && (
                        <View
                          style={[{
                            position: "absolute",
                            opacity: hovered ? 1 : 0,
                            flexDirection: "row",
                            transition: "0.2s all",
                            backgroundColor: background.mailBody,
                            minHeight: heightS * 33,
                            zIndex: 5,
                          }, styles.shadow]}
                        >
                          <Text
                            style={[
                              styles.headers,
                              lang === " he"
                                ? { marginLeft: (width / 100) * 3 }
                                : { marginRight: widthS * 3 },
                            ]}
                          >
                            {t("to")}:
                          </Text>
                          <Text
                            selectable={false}
                            style={styles.headers_details}
                          >
                            {newMessage.to_list[newMessage.to_list.length - 2] ===
                              ","
                              ? newMessage.to_list.slice(
                                0,
                                newMessage.to_list.length - 2
                              )
                              : newMessage.to_list}
                          </Text>
                        </View>
                      )}
                    </>
                  )}
                </Pressable>
              </View>
              {newMessage && newMessage.cc_list && (
                <View style={[styles.headers_view, { zIndex: 4 }]}>
                  <Pressable style={{ flexDirection: "row", cursor: "default" }}>
                    {({ hovered }) => (
                      <>
                        <View
                          style={{
                            opacity: hovered && isEllipsisCc ? 0 : 1,
                            flexDirection: "row",
                            transition: "0.2s all",
                          }}
                        >
                          <Text
                            style={[
                              styles.headers,
                              lang === " he"
                                ? { marginLeft: (width / 100) * 3 }
                                : { marginRight: widthS * 3 },
                            ]}
                          >
                            {t("cc")}:
                          </Text>
                          <Text
                            ref={ccPlayersTextRef}
                            numberOfLines={1}
                            selectable={false}
                            style={styles.headers_details}
                          >
                            {newMessage.cc_list[newMessage.cc_list.length - 2] === ","
                              ? newMessage.cc_list.slice(
                                0,
                                newMessage.cc_list.length - 2
                              )
                              : newMessage.cc_list}
                          </Text>
                        </View>
                        {isEllipsisCc && (
                          <View
                            style={[{
                              position: "absolute",
                              opacity: hovered ? 1 : 0,
                              flexDirection: "row",
                              transition: "0.2s all",
                              backgroundColor: background.mailBody,
                              minHeight: heightS * 33,
                              zIndex: 4,
                            }, styles.shadow]}
                          >
                            <Text
                              style={[
                                styles.headers,
                                lang === " he"
                                  ? { marginLeft: (width / 100) * 3 }
                                  : { marginRight: widthS * 3 },
                              ]}
                            >
                              {t("cc")}:
                            </Text>
                            <Text
                              selectable={false}
                              style={styles.headers_details}
                            >
                              {newMessage.cc_list[newMessage.cc_list.length - 2] === ","
                                ? newMessage.cc_list.slice(
                                  0,
                                  newMessage.cc_list.length - 2
                                )
                                : newMessage.cc_list}
                            </Text>
                          </View>
                        )}
                      </>
                    )}
                  </Pressable>
                </View>
              )}
              {newMessage && newMessage.bcc_list && (
                <View style={[styles.headers_view, { zIndex: 4 }]}>
                  <Pressable style={{ flexDirection: "row", cursor: "default" }}>
                    {({ hovered }) => (
                      <>
                        <View
                          style={{
                            opacity: hovered && isEllipsisCc ? 0 : 1,
                            flexDirection: "row",
                            transition: "0.2s all",
                          }}
                        >
                          <Text
                            style={[
                              styles.headers,
                              lang === " he"
                                ? { marginLeft: (width / 100) * 3 }
                                : { marginRight: widthS * 3 },
                            ]}
                          >
                            {t("bcc")}:
                          </Text>
                          <Text
                            ref={bccPlayersTextRef}
                            numberOfLines={1}
                            selectable={false}
                            style={styles.headers_details}
                          >
                            {newMessage.bcc_list[newMessage.bcc_list.length - 2] === ","
                              ? newMessage.bcc_list.slice(
                                0,
                                newMessage.bcc_list.length - 2
                              )
                              : newMessage.bcc_list}
                          </Text>
                        </View>
                        {isEllipsisCc && (
                          <View
                            style={[{
                              position: "absolute",
                              opacity: hovered ? 1 : 0,
                              flexDirection: "row",
                              transition: "0.2s all",
                              backgroundColor: background.mailBody,
                              minHeight: heightS * 33,
                              zIndex: 4,
                            }, styles.shadow]}
                          >
                            <Text
                              style={[
                                styles.headers,
                                lang === " he"
                                  ? { marginLeft: (width / 100) * 3 }
                                  : { marginRight: widthS * 3 },
                              ]}
                            >
                              {t("bcc")}:
                            </Text>
                            <Text
                              selectable={false}
                              style={styles.headers_details}
                            >
                              {newMessage.bcc_list[newMessage.bcc_list.length - 2] === ","
                                ? newMessage.bcc_list.slice(
                                  0,
                                  newMessage.bcc_list.length - 2
                                )
                                : newMessage.bcc_list}
                            </Text>
                          </View>
                        )}
                      </>
                    )}
                  </Pressable>
                </View>
              )}
              <View
                style={[
                  styles.attach_view,
                  {
                    justifyContent:
                      newMessage && newMessage.has_attachments
                        ? "space-between"
                        : "flex-end",
                  },
                ]}
              >
                {newMessage && newMessage.file?.length > 0 && (
                  <View style={styles.attach_container}>
                    <Entypo name="attachment" size={"0.9vw"} color={color.mailAttachName} />
                    <View
                      style={{ flexWrap: "wrap", flexDirection: "row", maxWidth: "20vw" }}
                    >
                      {newMessage.file.map((file, i) => {
                        return (
                          <Pressable
                            key={i}
                            onPress={() => {
                              isPossibletoClose.current = false;
                              dispatch(
                                showFile({
                                  name: file.name,
                                  type: file.type,
                                  link: file?.link || file?.uri,
                                })
                              )
                            }
                            }
                          >
                            <Text
                              style={[
                                styles.attach_text,
                                { color: color.mailAttachName },
                              ]}
                            >
                              {ShortenWord(file.name, 10)} .{file.name.slice(file.name.lastIndexOf("."))}
                            </Text>
                          </Pressable>
                        )
                      })}
                    </View>
                  </View>
                )}
              </View>
            </View>
            {/* body-newMessage */}
            {displayBody(newMessage, Platform)}
          </ScrollView>}
      </View>
      {(isEditing || !platform_type) &&
        <View style={{ flexDirection: "row", alignSelf: "flex-end", alignItems: "center", justifyContent: "center", marginBottom: "1vw" }}>
          {/* <Pressable
            onPress={() => {
              if (isEditing) {
                setIsEditing(false);
              } else {
                closePopup();
              }
            }}
            style={[
              styles.saveBtn, [
                {
                  borderWidth: 0,
                },
              ],
              { margin: 0 },
            ]}
          >
            <Text
              style={[
                styles.saveBtn_text,
                {
                  color: color.infoHeaderNavText,
                },
              ]}
            >
              {t("cancel")}
            </Text>
          </Pressable> */}
          <Pressable
            onPress={() => {
              let completed_action = checkActionFields();
              if (checkFields() && completed_action && isCompletedMaillForm) {
                setActionPost(newMessage, "", true);
              }
            }}
            style={[
              styles.saveBtn,
              {
                backgroundColor: background.infoSaveBtn,
              }
              ,
              { margin: 0 },
            ]}
          >
            <Text
              style={[
                styles.saveBtn_text, {
                  color: color.text2,
                }
              ]}
            >
              {t("save")}
            </Text>
          </Pressable>
        </View>}
    </View>
  );
}

const stylesR = (width, height, heightS, widthS, background, color) =>
  StyleSheet.create({
    main: {
      paddingVertical: "0.5vw",
      borderRadius: "1vw",
      marginBottom: "1vw"
    },
    body: {
      display: "flex",
      flexDirection: "column",
      // paddingHorizontal: widthS * 8,
      zIndex: 9,
      // paddingTop: heightS * 4,
      height: "inherit"
    },
    body_m: {
      display: "flex",
      flexDirection: "column",
      paddingRight: widthS * 8,
      zIndex: 9,
      paddingTop: heightS * 4,
      minHeight: "99.5%",
    },
    header: {
      zIndex: 20,
      display: "flex",
      flexDirection: "column",
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
    },
    top: {
      position: "relative",
      paddingBottom: heightS * 7,
      zIndex: 5,
    },
    subject: {
      fontSize: "1.2vw",
      fontFamily: "Catamaran_700Bold",
      lineHeight: heightS * 18,
      marginBottom: heightS * 7,
    },
    headers_view: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    headers: {
      fontSize: "1vw",
      lineHeight: heightS * 14,
      fontFamily: "Catamaran_600SemiBold",
    },
    headers_details: {
      fontSize: "1vw",
      lineHeight: heightS * 14,
      fontFamily: "Catamaran_400Regular",
      maxWidth: "18vw"
    },
    attach_view: {
      marginTop: heightS * 3,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      zIndex: 1,
    },
    attach_container: {
      flexDirection: "row",
      alignItems: "center",
    },
    attach_text: {
      fontSize: "0.9vw",
      fontFamily: "Catamaran_400Regular",
      marginHorizontal: widthS * 3,
      textDecorationStyle: "solid",
      textDecorationLine: "underline",
    },
    body_content: {
      paddingVertical: "0.5vw",
      flex: 1,
      // minHeight: "100%",
    },
    body_text: {
      fontSize: "1vw",
      fontFamily: "Catamaran_400Regular",
    },
    from_view: {
      paddingVertical: "0.3vw",
      display: "flex",
      flexDirection: "row",
      minWidth: "15vw",
      alignItems: "center",
      zIndex: 13,
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
    },
    label_for: {
      fontSize: "0.9vw",
      fontFamily: "Catamaran_800ExtraBold",
      marginRight: "0.3vw"
    },
    label_text: {
      fontSize: "1vw",
      fontFamily: "Catamaran_600SemiBold",
      width: "96%",
    },
    label_text_input: {
      fontSize: "0.9vw",
      fontFamily: "Catamaran_400Regular",
      lineHeight: heightS * 12,
      maxHeight: heightS * 15,
      marginLeft: widthS * 3,
      display: "flex",
      alignItems: "center",
    },
    _btn: {
      borderRadius: widthS * 5,
      display: "flex",
      flexDirection: "column",
      zIndex: 3,
      width: "15vw",
      minWidth: "fit-content",
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
    },
    _btn_open: {
      display: "flex",
      flexDirection: "column",
      zIndex: 3,
      width: "15vw",
      minWidth: "fit-content",
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      borderTopLeftRadius: "0.3vw",
      borderTopRightRadius: "0.3vw",
    },
    _btn_head: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minHeight: "4vh",
      alignItems: "center",
    },
    attach_view: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignSelf: "flex-start",
      justifyContent: "space-between",
      marginBottom: height * 5,
      alignItems: "center",
    },
    _btn_body: {
      width: "100%",
      borderBottomLeftRadius: "0.3vw",
      borderBottomRightRadius: "0.3vw",
      borderTopWidth: 1,
      borderTopColor: color.mailBorder
    },
    shadow: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.8,
      shadowRadius: 3,
    },
    to_view: {
      paddingVertical: "0.3vw",
      display: "flex",
      flexDirection: "row",
      zIndex: 12,
      alignItems: "center",
      minWidth: "15vw",
    },
    to_view_child: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: "space-between",
      alignItems: "center",
      flex: 1,
    },
    cc_view: {
      paddingVertical: "0.3vw",
      display: "flex",
      flexDirection: "row",
      zIndex: 11,
      alignItems: "center",
      minWidth: "15vw",
    },
    bcc_view: {
      paddingVertical: "0.3vw",
      display: "flex",
      flexDirection: "row",
      zIndex: 10,
      alignItems: "center",
      minWidth: "15vw",
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
    },
    borderBottomHeader: {
      borderBottomColor: color.mailBorder2,
      borderBottomWidth: "0.1vw",
    },
    subject_view: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      alignItems: "center",
      paddingVertical: "0.3vw",
      alignItems: "center",
      zIndex: 6,
    },
    inp_subject: {
      width: "92%",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
      // maxWidth: "96%",
      // lineHeight: heightS * 12,
      height: "4vh",
    },
    text_area: {
      borderWidth: 1,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
      outlineStyle: "none",
    },
    // webView: {
    //  width: 200,
    //  height: 200,
    // },
    footer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      height: heightS * 23,
      borderTopWidth: 1,
      zIndex: 8,
      paddingTop: heightS * 5
    },
    footer_child: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      // flex: 1,
      zIndex: 2,
    },
    toolbar_btns_hovered: {
      shadowColor: "black",
      shadowOffset: { width: 0, height: 0 },
      shadowOpacity: 0.9,
      shadowRadius: 2,
      width: widthS * 15,
      height: widthS * 14,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 2,
    },
    toolbar_btns: {
      width: widthS * 15,
      height: widthS * 14,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: widthS * 2,
    },
    attach_name: {
      marginLeft: widthS * 5,
      fontSize: widthS * 11,
      fontFamily: "Catamaran_400Regular",
    },
    send_btn: {
      minWidth: widthS * 48,
      height: heightS * 16,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1,
      borderRadius: widthS * 5,
    },
    send_text: {
      color: "black",
      fontSize: widthS * 9,
      fontFamily: "Catamaran_400Regular",
    },
    draftsHeader: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      height: heightS * 30,
      paddingRight: widthS * 18,
      zIndex: 8,
    },
    new_btn: {
      minWidth: widthS * 45,
      paddingHorizontal: widthS * 3,
      paddingVertical: heightS * 1.5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: widthS * 6,
    },
    new_text: {
      color: "black",
      fontSize: widthS * 10,
      fontFamily: "Catamaran_400Regular",
    },
    search_press: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      borderRadius: widthS * 5,
      marginHorizontal: widthS * 4,
      paddingRight: widthS * 4,
    },
    search_input: {
      width: widthS * 155,
      paddingLeft: widthS * 8,
      paddingRight: widthS * 8,
      paddingVertical: heightS * 2,
      fontSize: widthS * 9,
      fontFamily: "Catamaran_600SemiBold",
    },
    search_icon: {
      width: widthS * 9.8,
      height: widthS * 9.8,
      resizeMode: "contain",
    },
    body_child_c: {
      height: "100%",
      maxHeight: "100%",
    },
    body_child: {
      minHeight: "100.1%",
    },
    line: {
      height: heightS * 15,
      borderRadius: widthS * 3,
      // marginTop: heightS * 4,
      // marginBottom: heightS * 2,
      marginHorizontal: widthS * 3,
      width: widthS * 0.7,
    },
    input: {
      height: heightS * 30,
      fontSize: widthS * 8,
      marginVertical: heightS * 5,
      borderRadius: widthS * 9,
      paddingVertical: heightS * 15,
      paddingHorizontal: widthS * 10,
      outlineStyle: "none",
    },
    selectBtn: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      position: "relative",
    },
    saveBtn: {
      paddingHorizontal: widthS * 8,
      paddingVertical: heightS * 4,
      borderRadius: widthS * 5,
      alignItems: "center",
      justifyContent: "center",
      marginRight: widthS * 10,
      width: "5vw"
    },
    saveBtn_text: {
      fontSize: 10 * widthS,
    },

    saveBtnUncompleted: {
      borderWidth: 1,
    },
    copy: {
      flexDirection: "row",
      justifyContent: "flex-end",
      paddingRight: widthS * 5,
    },
    copyText: {
      fontSize: widthS * 8,
      color: "black"
    },
    bcc: {
      marginHorizontal: widthS * 5,
    },
    scroll_c: {
      maxHeight: heightS * 200,
      display: "flex",
      zIndex: 1,
    },
    scroll_m: {
      minHeight: heightS * 180,
      zIndex: 9,
    },
    mailPreviewBody: {
      borderTopRightRadius: widthS * 8,
      borderTopLeftRadius: widthS * 8,
      paddingHorizontal: widthS * 10,
      paddingVertical: heightS * 10,
    },
    // attach_view: {
    //   display: "flex",
    //   flexDirection: "row",
    //   width: widthS * 145,
    //   alignSelf: "flex-end",
    //   justifyContent: "space-between",
    //   marginBottom: height * 5,
    //   paddingHorizontal: widthS * 5,
    //   alignItems: "center",
    //   borderRadius: widthS * 5,
    //   // borderWidth: widthS * 1,
    //     marginRight:10
    // },




    errText: {
      color: color.infoSaveBtn,
      fontSize: "0.8vw",
      fontFamily: "Catamaran_400Regular",
      lineHeight: "0.8vw",
      textAlign: "end"
    },
    mail_error: {
      borderColor: "red",
      borderWidth: "2px",
      borderRadius: widthS * 9,
    },
    popup_list: {
      maxHeight: heightS * 110,
      minHeight: heightS * 20,
      zIndex: 100,
    },
    popup_list_content: {
      // backgroundColor: "#ebebeb",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      borderRadius: widthS * 9,
    },
    // label_for: {
    //   fontSize: widthS * 9,
    //   fontFamily: "Catamaran_600SemiBold",
    //   paddingLeft: widthS * 3,
    //   color: "black"
    // },
    list_item: {
      zIndex: 13,
      paddingHorizontal: "0.3vw",
      paddingVertical: "0.2vh",
    },
    list_item_text: {
      zIndex: 13,
      marginVertical: "0.3vh",
      marginLeft: widthS * 7,
      fontSize: "0.9vw",
      lineHeight: "1.1vw",
      fontFamily: "Catamaran_500Medium",
      display: "flex",
      alignItems: "center",
    },
    search_bar_pressable: {
      flex: 1
    },
    viewItems: {
      flexDirection: "row",
      flexWrap: "wrap",
      maxHeight: "8vh",
      overflowY: "scroll",
      maxWidth: "100%"
    },
    viewItem: {
      flexDirection: "row",
      alignItems: "center",
      backgroundColor: background.mailPopupItems,
      paddingLeft: "0.5vw",
      paddingVertical: "0.2vw",
      borderRadius: "0.3vw"
    },
    viewItemWrap: {
      margin: "0.1vw"
    },
    x_item: {
      paddingHorizontal: "0.6vw",
      fontSize: "0.7vw",
      color: color.x,
      fontFamily: "Catamaran_500Medium"
    }
  });